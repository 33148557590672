<script>
  import { _ } from 'svelte-i18n';

  import { rightToLeft } from '../../../js/stores';

  import Button from '../Button.svelte';
  import EmergencyExitSeatDisclaimer from './seats/EmergencyExitSeatDisclaimer.svelte';
  import SeatContentButtons from './SeatContentButtons.svelte';
  import Seat from './Seat.svelte';

  import x from '../../../svg/x.svg';

  export let description = null;
  export let hasError = null;
  export let isOpen = null;
  export let passenger = null;
  export let passengerSeats = null;
  export let seat = null;

  let isSeatContentVariation = true;

  $: hasAgreedToConditions = false;

  /** Handle close of the tooltip. */
  function closeMenuHandler() {
    hasAgreedToConditions = false;
    hasError = false;
    isOpen = false;
  }
</script>

<style>.seatContent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}</style>

{#if isOpen}
  <div class="seatContent" dir={$rightToLeft ? 'rtl' : 'ltr'}>
    <div class="bg-white m-2 p-4 relative shadow-xl">
      {#if hasError}
        <div class="mb-4">
          <p class="text-lg whitespace-pre-wrap">{$_('seatMap.error')}</p>
        </div>
        <div class="flex items-end">
          <Button class="px-8" size="small" on:click={closeMenuHandler}>
            {$_('app.ok')}
          </Button>
        </div>
      {:else}
        <div class="flex justify-between">
          {#if seat.isEmergencyExit() && !hasAgreedToConditions}
            <div class="w-full">
              <EmergencyExitSeatDisclaimer
                {closeMenuHandler}
                bind:hasAgreedToConditions
              />
            </div>
          {:else}
            <div>
              <Seat {isSeatContentVariation} {passengerSeats} {seat} />

              <div class="text-lg">
                <p>{seat} ({$_('seatMap.selected')})</p>

                {#if description}
                  <p class="whitespace-no-wrap">
                    {$_(`seatMap.${description}`)}
                  </p>
                {/if}

                <p class="font-AltisMedium">
                  {#if seat.isPaid()}
                    {$_('seatMap.nonRefundable')}
                  {:else}
                    <span class="invisible">.</span>
                  {/if}
                </p>
              </div>
            </div>

            <div class="flex items-end">
              <SeatContentButtons
                {closeMenuHandler}
                {seat}
                {passenger}
                on:openPaymentModal
                on:updateSelectedSeat
              />
            </div>
          {/if}
        </div>

        <div class="absolute p-4 top-0 {$rightToLeft ? 'left-0' : 'right-0'}">
          <button on:click={closeMenuHandler} class="px-2 py-1">
            <div class="w-7 text-charcoal-100">
              {@html x}
            </div>
          </button>
        </div>
      {/if}
    </div>
  </div>
{/if}
