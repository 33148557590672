<script>
  import Bassinet from './seats/Bassinet.svelte';

  export let seat = null;
  export let seatType = null;
  export let isSeatContentVariation = false;
  export let text = null;
</script>

<style>.seat {
  position: relative;
  height: 3rem;
  width: 3rem;
}

.bassinet {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 1.75rem;
}

.bulkhead {
  position: absolute;
  border-top-width: 2px;
  --border-opacity: 1;
  border-color: #bd8b13;
  border-color: rgba(189, 139, 19, var(--border-opacity));
  left: 0;
  margin-top: -2.5rem;
  right: 0;
  top: 0;
  margin-left: -6px;
  margin-right: -6px;
}

.lavatory {
  padding: 0.25rem;
}

.text {
  position: absolute;
  display: flex;
  font-family: AltisMedium;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}</style>

<div class="seat" data-seatNumber={seat}>
  {#if text}
    <div class="text">
      <p
        class="{seat.isRearFacing()
          ? 'mt-2'
          : 'mb-2'} bg-white leading-none
        text-0.625 uppercase"
      >
        {text}
      </p>
    </div>
  {/if}

  {#if !isSeatContentVariation}
    {#if seat.isBulkhead()}
      <div class="bulkhead" />
    {/if}
    {#if seat.isBassinet()}
      <div
        class="bassinet {seat.isRearFacing()
          ? 'bottom-0 -mb-8'
          : 'top-0 -mt-8'}"
      >
        <Bassinet />
      </div>
    {/if}
  {/if}

  <div class:lavatory={seat.isLavatory()}>
    <svelte:component this={seatType} />
  </div>
</div>
