<script>
  import { _ } from 'svelte-i18n';
  import { push } from 'svelte-spa-router';
  import { get } from 'svelte/store';
  import { onMount } from 'svelte';
  import { handleTransactionEnd } from '../../../js/handleTransactionEnd';  
  import { AGENT_ACTIONS, ApplicationStep, appReport, EventStatus, EventType, FailedReason, USER_ACTIONS } from '../../../js/appReport';
  import switchboardClient from '../../../js/services/switchboard';
  import logger from '../../../js/logger';
  import Loading from '../../components/Loading.svelte';
  import {    
    booking,
    headPassenger,
    CustomErrorModal,
    passengerBagCounts,
    retrieveBagTags,
    setErrorModal,
    wizardPosition,
  } from '../../../js/stores';
  import {
  baseStationCode
} from '../../../js/stores/config';
  import { 
    isStaffForceAcceptanceEnforced,
    uniqueBagIdentifierList,
    uniqueBagIdentifierPriorityList
  } from '../../../js/stores/bags';
  import { ErrorModals } from '../../../js/const/errorModals';
  import flightdeck from '../../../js/services/flightdeck';
  import flightdeckConsts from '../../../js/services/flightdeck/const';
  import { VoiceIntent } from '../../../js/services/voicerec/voicerec';
  import { AllowanceType, WizardPosition } from '../../../js/const';  
  import Button, { ButtonKinds } from '../../components/Button.svelte';
  import FooterSecondary from '../../components/FooterSecondary.svelte';
  import ItemsContainer from './ItemsContainer.svelte';
  import hasRecordInUseError from '../../../js/recordInUseError';

  let serviceRetryCount = 0;
  let dgrErrorModal;
  let isLoading = false;

  export let showDangerousGoodsHeading = true;

  wizardPosition.set(WizardPosition.PASSENGERS);

  /*
   * Advance to the next screen as per the applicationFlow.
   *
   * Select screen based on applicationFlow mode.
   * Initiate the graphql call for issuing bag tag.
   */
  function advanceToNextScreen() {
    isLoading = true;
    showDangerousGoodsHeading = false;

    appReport.updateStepSuccess(ApplicationStep.DANGEROUS_GOODS);
    
    // now that passenger has selected no bags in the Number of bags screen.
    // we will show DG, and if passenger is not carrying DG, we go to BP print screen
    if (passengerBagCounts.totalBags() === 0) {
      push('/bag-drop-completed');
      isLoading = false;
      showDangerousGoodsHeading = false;
      return;
    }

    baggageAcceptance();
  }

  function baggageAcceptance() {
    // // if there is a baggage pool, we do not need to call accept baggage group
    if (!get(booking).isBaggageInfoForScannedPassenger) {      
      acceptBaggageGroup(passengerBagCounts.totalBags());
    } else {
      // not passing baggage Reference, since it would be available in booking response
      proceedWithBagTagsRetrieval(null);
    }      
  }

  function proceedWithBagTagsRetrieval(baggageReference) {
    isLoading = false;
    showDangerousGoodsHeading = false;
    retrieveBagTags(flightdeck, baggageReference);
    push('/retrieving-bag-tags');  
  }
 
  function acceptBaggageGroup(numberOfBags) {    
    let eventDetails = { numberOfBags: numberOfBags, action: USER_ACTIONS.NUMBER_BAGS_SELECTED }

    appReport.updateStepWithEventDetails(
      ApplicationStep.ACCEPT_BAGGAGE_GROUP,
      EventType.STEP_IN_PROGRESS,
      EventStatus.SUCCESS,
      eventDetails
    );

    if ($booking) {
      let baggageRouteList = [];
      let passengerList = [];
      let index = 0;
      
      logger.log('Passengers in booking store are: ', $booking.passengers);

      // const passengersWithoutInfants = headPassengerManager.sortPassengersWithoutInfants();
      // passengersWithoutInfants.forEach((passenger) => {      
      $booking.passengers.forEach((passenger) => {
        if (passenger.passengerType !== 'INFANT') {
          let passengerDetail = {
            isStaff: passenger.isStaff,
            surname: passenger.lastName,
            type: passenger.passengerType,
            customerPrimeId: passenger.passengerID,
            productDetailsList: getProductDetails(passenger.segments),
          };
          if (index === 0) {
            baggageRouteList = getBaggageRoutes(passenger.segments);
            index++;
          }
          passengerList.push(passengerDetail);
        }
      });

      switchboardClient
        .acceptBaggageGroup(
          passengerList,
          baggageRouteList,
          numberOfBags,
          getTotalWeightAllowed(),
          $booking.departureDateTime,
          false,
          baseStationCode
        )
        .then((response) => {
            
            let acceptBaggageGroupResponse = response?.data?.acceptBaggageGroup;
          
            uniqueBagIdentifierList.set(
              acceptBaggageGroupResponse?.uniqueBagIdentifierList,
            );

            logger.info('staff force acceptance enforced is: ', acceptBaggageGroupResponse?.isStaffForceAcceptanceEnforced)
            isStaffForceAcceptanceEnforced.set(acceptBaggageGroupResponse?.isStaffForceAcceptanceEnforced)
            
            let baggageReference = acceptBaggageGroupResponse?.baggageReference;
            // Update the priority UBI list with new bags from accept baggage info
            let currentUniqueBagIdentifierPriorityList = get(uniqueBagIdentifierPriorityList)
            let newUBIPriorityList = currentUniqueBagIdentifierPriorityList.concat(acceptBaggageGroupResponse?.uniqueBagIdentifierPriorityList)
            uniqueBagIdentifierPriorityList.set(newUBIPriorityList)

            const errorMessages = acceptBaggageGroupResponse?.errorMessages;
            if (errorMessages && errorMessages.length > 0) {
              logger.error('Errors received from Accept baggage Group Amadeus Service.')

              // check if record in use error is received
              if (hasRecordInUseError(errorMessages)) {
                serviceRetryCount++;
                if (serviceRetryCount < 3) {
                  logger.info('Record in use error received from AcceptBaggageGroup. waiting three seconds to recall the service')
                  setTimeout(() => {
                    acceptBaggageGroup(numberOfBags);
                    return;
                  }, 3000);
                  return;
                } else {
                  logger.info('Record in use error received from AcceptBaggageGroup. Maximum retry attempts reached. Ending transaction.')
                }
              }


              errorMessages.forEach((error) => {
                logger.error(error?.message);
              });
               flightdeck.bagAcceptanceFailed();
               setErrorModal(ErrorModals.BAG_ACCEPTANCE_ERROR);
               isLoading = false;
               showDangerousGoodsHeading = true;
               return; // we do not want to go to next screen Accept baggage had errors
            }
            
            appReport.updateStepSuccess(ApplicationStep.ACCEPT_BAGGAGE_GROUP);
            proceedWithBagTagsRetrieval(baggageReference);
          },
          (error) => {
            logger.error(error);
            appReport.updateStepFailed(
              ApplicationStep.ACCEPT_BAGGAGE_GROUP,
              FailedReason.SWITCHBOARD_CALL_FAILED
            );
          },
        );
    }
  }

  function getBaggageRoutes(segments) {
  const currentBooking = get(booking);
  let baggageRoutesList = [];
  let seenProductIds = [];
  // If triangular flight is detected, handle it specially
  if (currentBooking.isBookingTriangularFlight) {
    segments.forEach((segment) => {
      if (!seenProductIds.includes(segment.passengerDID)) {
        let allSegmentsWithProductID = segments.filter(
          (s) => (s.passengerDID = segment.passengerDID),
        );

        // This should never happen but if for some reason segments is modified during the loop or similar
        if (!allSegmentsWithProductID) {
          logger.error(
            `No segment was found with a matching DID: ${segment.passengerDID}`,
          );
          return;
        }

        // Get the offPoint from the last segment to handle multi-leg product updates
        let lastSegmentWithMatchingProductId =
          allSegmentsWithProductID[allSegmentsWithProductID.length - 1];

        let baggageRoutes = {
          productFlightDetails: {
            marketingCarrier: segment.airlineCode,
            flightNumber: segment.flightNumber,
            departureDate: segment.departureDateTime,
            boardPoint: segment.departureCode,
            offPoint: lastSegmentWithMatchingProductId.arrivalCode,
          },
        };

        baggageRoutesList.push(baggageRoutes);
        seenProductIds.push(segment.passengerDID);
      }
    });
  } else {
    segments.forEach((segment) => {
      let baggageRoutes = {
        productFlightDetails: {
          marketingCarrier: segment.airlineCode,
          flightNumber: segment.flightNumber,
          departureDate: segment.departureDateTime,
          boardPoint: segment.departureCode,
          offPoint: segment.arrivalCode,
        },
      };
      baggageRoutesList.push(baggageRoutes);
    });
  }

  return baggageRoutesList;
}

  function getProductDetails(segments) {
    let productDetailsList = [];
    segments.forEach((segment) => {
      let productDetails = {
        productFlightDetails: {
          marketingCarrier: segment.airlineCode,
          flightNumber: segment.flightNumber,
          departureDate: segment.departureDateTime,
          boardPoint: segment.departureCode,
          offPoint: segment.arrivalCode,
        },
        productPrimeId: segment.passengerDID,
      };
      productDetailsList.push(productDetails);
    });

    logger.info(JSON.stringify(productDetailsList));

    return productDetailsList;
  }

  function getTotalWeightAllowed() {
    let totalWeightAllowed = 0;
    if (booking.totalAllowanceByType(AllowanceType.EMD)) {
      totalWeightAllowed =
        totalWeightAllowed + booking.totalAllowanceByType(AllowanceType.EMD);
    }

    if (booking.totalAllowanceByType(AllowanceType.VCR)) {
      totalWeightAllowed =
        totalWeightAllowed + booking.totalAllowanceByType(AllowanceType.VCR);
    }

    if (booking.totalAllowanceByType(AllowanceType.PGCU)) {
      totalWeightAllowed =
        totalWeightAllowed +
        booking.totalAllowanceByType(AllowanceType.PGCU);
    }
    return totalWeightAllowed;
  }

  function handleFlightDeckOverride(flightdeckAction) {
    advanceToNextScreen();
    return true;
  }

  function handleFlightDeckCancelled(flightdeckAction) {
    appReport.updateStepWithAction(
      ApplicationStep.DANGEROUS_GOODS,
      EventType.AGENT_ACTION,
      EventStatus.SUCCESS,
      AGENT_ACTIONS.DGR_DECLINED_FLIGHTDECK_TRANSACTION_END
    );
    
    handleTransactionEnd();
    
    return true;
  }

  /** Show the assistance required modal. */
  function showAssistanceModal() {
    flightdeck.dangerousGoods(get(booking), get(headPassenger));
    dgrErrorModal.open();    
  }

  onMount(() => {
    appReport.updateStepStart(ApplicationStep.DANGEROUS_GOODS);

    dgrErrorModal = new CustomErrorModal(ErrorModals.DGR_DECLINED);
    dgrErrorModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Overridden,
      handleFlightDeckOverride,
    );
    dgrErrorModal.setFlightdeckHandler(
      flightdeckConsts.TransactionStatuses.Cancelled,
      handleFlightDeckCancelled,
    );
    dgrErrorModal.setOverrideHandler(handleFlightDeckOverride);
  });

  function onBackClick() {
    logger.info('User decides to Go Back to Number of Bags screen.');
    push('/number-of-bags');
  }
</script>

<style>.dangerousGoods {
  width: 91.666667%;
  margin-left: auto;
  margin-right: auto;
}

.dangerousGoods ol {
  font-family: AltisMedium;
  line-height: 2rem;
  list-style-type: decimal;
  margin-bottom: 1.5rem;
}

.dangerousGoods ol li {
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.25rem;
  font-size: 1.3rem;
}</style>


{#if isLoading === false }
  <ItemsContainer />

  <div class="dangerousGoods">
    <ol>
      <li>{@html $_(`dangerousGoods.questions.question1`)}</li>
      <li>{@html $_(`dangerousGoods.questions.question2`)}</li>
      <li>{@html $_(`dangerousGoods.questions.question3`)}</li>
    </ol>
  </div>

  <div style="width: 100%; text-align: center; margin-top: 20px">        
    <Button
      kind={ButtonKinds.PRIMARY}
      on:click={onBackClick}
    >
      {$_('app.back')}
    </Button>        
  </div>

  <FooterSecondary
    buttonLeftHandler={showAssistanceModal}
    buttonLeftIntent={VoiceIntent.YES}
    buttonLeftKind={ButtonKinds.SECONDARY}
    buttonLeftText={$_('app.yes')}
    buttonRightHandler={advanceToNextScreen}
    buttonRightIntent={VoiceIntent.NO}
    buttonRightText={$_('app.no')}
  />
{:else}      
  <Loading heading={$_('app.pleaseWait')} />
{/if}
