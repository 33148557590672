<script>
  import Lavatory from '../../../svg/lavatory.svg';

  export let row = null;
  export let seatMapManager = null;
</script>

<style>.rowFacilities {
  display: flex;
  justify-content: space-around;
}</style>

<div class="rowFacilities">
  {#each seatMapManager.getRowFacilities(row) as facility}
    {#if facility.characteristics === 'Lavatory'}
      <div class="pb-5">
        <div class="bg-charcoal-10 flex justify-center my-4 w-27">
          <div class="w-10 py-3">
            {@html Lavatory}
          </div>
        </div>
      </div>
    {:else}
      <div class="w-27" />
    {/if}
  {/each}
</div>
