<script>
  import { _ } from 'svelte-i18n';

  import { booking } from '../../../js/stores';

  import Seat from './Seat.svelte';

  import arrow from '../../../svg/arrow.svg';
  import checkCircle from '../../../svg/checkCircle.svg';

  export let controller = null;
  export let flightNumber = null;
  export let passengers = null;
  export let seat = null;
  export let segment = null;

  let isExpanded = false;

  /** Handle the dropdown button. */
  function expandHandler() {
    isExpanded = !isExpanded;
  }
</script>

<style>.seatingSummary {
  border-color: currentColor;
  border-bottom-width: 1px;
  border-top-width: 1px;
}</style>

<!-- @TODO: remove false when SeatingSummary is working correctly. -->
{#if false}
  <div class="seatingSummary">
    <button
      class="flex font-bold items-center justify-between px-2 w-full"
      on:click={expandHandler}
    >
      <div class="flex-1 text-left">
        <p class="pt-4">{$_('seatMap.seatingSummary')}</p>
      </div>

      <div class="flex items-center justify-end">
        <p class="pt-2 px-4 text-xl uppercase">
          {controller.calculatePaymentTotal()}
        </p>

        <div class="py-2">
          <div class="transform w-8 {isExpanded ? '-rotate-90' : 'rotate-90'}">
            {@html arrow}
          </div>
        </div>
      </div>
    </button>

    {#if isExpanded}
      <div class="absolute bg-white w-full">
        <div class="border-b border-current">
          <div class="pb-2 px-2 text-sm w-full">
            <div class="border-current border-t">
              <p class="pt-2">
                {segment.departureCode} - {segment.arrivalCode}
              </p>
              <!-- @TODO add seat summary description. 
              <Seat {seat} />
              <p>9 x Selected Economy Space Seats</p>
            -->
              <div class="flex items-center text-palmGreen">
                <div class="-mx-1 flex">
                  {#each passengers as passenger}
                    <p class="px-1">
                      {booking.getSeatNumber(passenger, flightNumber)}
                    </p>
                  {/each}
                </div>

                <div class="px-2">
                  <div class="w-4">
                    {@html checkCircle}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </div>
{/if}
